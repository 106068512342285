import React, { useState } from 'react';
// @ts-ignore
import { useSpeechRecognition } from 'react-speech-kit';
import { MicOutline, MicOffOutline } from 'react-ionicons';
import { Button } from 'semantic-ui-react';

// Dictaphone button which stores isListening-state in useState and fires an event once speech recognition is complete
// @ts-ignore
const DictaphoneButton = ({ handleTextUpdateCallback }) => {
  const [isListening, setIsListening] = useState(false);
  const [, /*text*/ setText] = useState('');

  const { listen, /*listening,*/ stop, supported } = useSpeechRecognition({
    onResult: (result: React.SetStateAction<string>) => {
      setText(result);
      handleTextUpdateCallback && handleTextUpdateCallback(result);
    },
  });

  if (!supported) {
    return (
      <Button disabled={true} color="red">
        <MicOffOutline />
      </Button>
    );
  }

  const startListening = () => {
    if (!isListening) {
      setIsListening(true);
      listen();
      console.log('Speech recognition started');
    } else {
      console.log('Speech recognition already started');
    }
  };

  const stopListening = () => {
    if (isListening) {
      stop();
      setIsListening(false);
      console.log('Speech recognition stopped');
    }
  };

  const handleClick = () => {
    isListening ? stopListening() : startListening();
  };

  return (
    <Button onClick={handleClick}>
      {isListening ? <MicOffOutline /> : <MicOutline />}
    </Button>
  );
};

export default DictaphoneButton;
