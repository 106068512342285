import { callApi } from '../../lib/api';

export interface SettingsState {
  theme: string;
  language: string;
  balance: number;
}

const initialState: SettingsState = {
  theme: 'light',
  language: 'en',
  balance: 0,
};

const settingsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'UPDATE_THEME':
      return {
        ...state,
        theme: action.theme,
      };
    case 'UPDATE_LANGUAGE':
      return {
        ...state,
        language: action.language,
      };
    case 'UPDATE_BALANCE':
      return {
        ...state,
        balance: action.balance,
      };
    default:
      return state;
  }
};

export default settingsReducer;

/*
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { callApi } from '../../lib/api';

/!**
 * The state for the "ApplicationSettings" slice.
 *!/
export interface SettingsState {
  /!**
   * The user's preferred theme (e.g. "light" or "dark").
   *!/
  theme: string;
  balance: number;
}

const initialState: SettingsState = {
  theme: 'light',
  balance: 0,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    /!**
     * Action to set the user's preferred theme.
     *
     * @param state - The current state of the store.
     * @param action - The action to perform.
     *!/
    setTheme(state, action: PayloadAction<string>) {
      state.theme = action.payload;
    },

    setBalance(state, action: PayloadAction<number>) {
      state.balance = action.payload;
    },
  },
});

export const { setTheme, setBalance } = settingsSlice.actions;

export default settingsSlice.reducer;
*/

/**
 * Asynchronously fetch the user's settings from the backend.
 * @returns A promise that resolves with the user's application settings.
 */
export function fetchSettings(currentUser: any): Promise<SettingsState> {
  return callApi('/settings', 'GET', null, {
    // @ts-ignore
    accessToken: currentUser?.stsTokenManager?.accessToken,
  });
}

/**
 * Asynchronously save the user's settings to the backend.
 * @param settings - The user's settings to save.
 * @returns A promise that resolves when the user's settings have been saved.
 */
export function saveSettings(settings: SettingsState): Promise<void> {
  return callApi('/settings', 'PATCH', JSON.stringify(settings), {
    // @ts-ignore
    accessToken: currentUser?.stsTokenManager?.accessToken,
  });
}
