import { FirebaseOptions, getApp, initializeApp } from 'firebase/app';
import {
  addDoc,
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  // signInWithRedirect,
  signOut,
  // connectAuthEmulator,
  Auth,
} from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import {
  getFunctions,
  connectFunctionsEmulator,
  Functions,
} from 'firebase/functions';

import firebaseJson from './firebase.json';

import cors from 'cors';

// const {
//   initializeAppCheck,
//   ReCaptchaV3Provider,
// } = require('firebase/app-check');

cors({ origin: false });

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const googleProvider = new GoogleAuthProvider();

/**
 * Sign in with Google SSO.
 * @returns {Promise<void>}
 */
export const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, 'users'), where('uid', '==', user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, 'users'), {
        uid: user.uid,
        name: user.displayName,
        authProvider: 'google',
        email: user.email,
        createdAt: new Date().toISOString(),
      });
    }
  } catch (err) {
    console.error(err);
    // @ts-ignore
    alert(err.message);
  }
};

/**
 * Logs a user in with an email and password.
 * @param email
 * @param password
 * @returns {Promise<void>}
 */
export const logInWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    // @ts-ignore
    alert(err.message);
  }
};

/**
 * Registers a new user with email and password.
 * @param name
 * @param email
 * @param password
 * @returns {Promise<void>}
 */
export const registerWithEmailAndPassword = async (
  name: string,
  email: string,
  password: string
) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const {
      user: { uid },
    } = res;
    await addDoc(collection(db, 'users'), {
      uid,
      name,
      authProvider: 'local',
      email,
      createdAt: new Date().toISOString(),
    });
  } catch (err) {
    console.error(err);
    // @ts-ignore
    alert(err.message);
  }
};
export const sendPasswordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert('Password reset link sent!');
  } catch (err) {
    console.error(err);
    // @ts-ignore
    alert(err.message);
  }
};
export const logout = () => {
  return signOut(auth);
};

function setupLocalEmulation(fn: Functions, auth: Auth) {
  if (
    (false && window.location.hostname === 'localhost') ||
    window.location.hostname === '127.0.0.1'
  ) {
    // console.log(
    //   'testing locally -- hitting local auth, functions and firestore emulators'
    // );

    // connectAuthEmulator(
    //   auth,
    //   `http://localhost:${firebaseJson.emulators.auth.port}`
    // );

    connectFunctionsEmulator(
      fn,
      'localhost',
      firebaseJson.emulators.functions.port
    );
  }
}

/**
 * Initialize Firebase but only once.
 * @param cfg
 * @returns {FirebaseApp}
 */
const initializeAppIfNecessary = (cfg: FirebaseOptions) => {
  try {
    return getApp();
  } catch {
    return initializeApp(cfg);
  }
};

export const app = initializeAppIfNecessary(firebaseConfig);

export const functions = getFunctions(app);

export const db = getFirestore(app);

export const auth = getAuth(app);

// export const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider(process.env.GOOGLE_RECAPTCHA_SITE_KEY),
//
//   // Optional argument. If true, the SDK automatically refreshes App Check
//   // tokens as needed.
//   isTokenAutoRefreshEnabled: true,
// });

setupLocalEmulation(functions, auth);

export const storage = getStorage(app);
