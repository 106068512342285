import { callApi } from '../../lib/api';
import { SettingsState } from '../settings/settingsSlice';

/**
 * Asynchronously fetch the user's settings from the backend.
 * @returns A promise that resolves with the user's application settings.
 */
export function fetchTransactions(currentUser: any): Promise<SettingsState> {
  return callApi('/transactions', 'GET', null, {
    // @ts-ignore
    accessToken: currentUser?.stsTokenManager?.accessToken,
  });
}
