import { /*createStore,*/ combineReducers /*, applyMiddleware*/ } from 'redux';
import { /*createSlice,*/ configureStore } from '@reduxjs/toolkit';

import comments, {
  addComment,
  removeComment,
} from './features/comments/commentsSlice';

import speaking, {
  startedSpeaking,
  stoppedSpeaking,
} from './features/speaking/speakingSlice';

import settings from './features/settings/settingsSlice';

// Combine the slices into a single root reducer
const rootReducer = combineReducers({
  comments,
  speaking,
  settings,
});

// Create the Redux store with the root reducer and apply any necessary middleware
const store = configureStore({
  reducer: rootReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
export {
  store,
  addComment,
  removeComment,
  startedSpeaking,
  stoppedSpeaking,
  // setBalance,
  // setTheme,
};
