import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  Chat,
  Login,
  Page,
  Payments,
  PaymentComplete,
  Register,
  Reset,
  Transactions,
} from '../index';

// @ts-ignore
const AppRoutes = ({ context }) => {
  const { currentUser, settings } = context;
  if (currentUser && settings) {
    return (
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/reset" element={<Reset />} />
        <Route path="/register" element={<Register />} />

        <Route
          path="/home"
          element={<Page component={Chat} context={context} />}
        />
        <Route
          path="/payments"
          element={<Page component={Payments} context={context} />}
        />
        <Route
          path="/payment-complete"
          element={<Page component={PaymentComplete} context={context} />}
        />
        <Route
          path="/transactions"
          element={<Page component={Transactions} context={context} />}
        />
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/reset" element={<Reset />} />
        <Route path="/register" element={<Register />} />
      </Routes>
    );
  }
};

export default AppRoutes;
