export type FixMeLater = any;

// TODO: put these constants in a config file
const PROJECT_NAME = `askgpt-b87fe`;
const PROJECT_LOCATION = `us-central1`;

// @ts-ignore
const DEV_API_PORT = parseInt(process.env.DEV_API_PORT) || 5001;

export function getBaseUrl() {
  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1'
  ) {
    return `http://${window.location.hostname}:${window.location.port}`;
  } else {
    return `https://askgpt.cc`;
  }
}

export function getApiUrl() {
  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1'
  ) {
    return `http://${window.location.hostname}:${DEV_API_PORT}/${PROJECT_NAME}/${PROJECT_LOCATION}/api`;
  } else {
    return `https://api-gd7k5sqaxa-uc.a.run.app`;
    // return `https://us-central1-askgpt-b87fe.cloudfunctions.net/api`;
  }
}

export function callApi(
  uri: string = '/',
  method: string = 'GET',
  body: FixMeLater = null,
  options: FixMeLater = {}
) {
  const init = {
    method,
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PUT, PATCH, DELETE, OPTIONS',
      'Access-Control-Allow-Headers': '*',
      // @ts-ignore
      // Bearer: options?.accessToken,
      Authorization: `Bearer ${options?.accessToken}`,
    },
    redirect: 'follow',
    // body: body ? JSON.stringify(body) : undefined,
  };

  if (options.method !== 'GET' && options.method !== 'HEAD' && body) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    Object.assign(init, { body: JSON.stringify(body) });
    // init.body = JSON.stringify(body);
  }

  // @ts-ignore
  return fetch(getApiUrl() + uri, init)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error('Error:', error);
    });
}
