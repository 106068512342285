import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { store } from '../../store';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebase';
import Cookies from 'universal-cookie';
import { fetchSettings } from '../../features/settings/settingsSlice';
import AppRoutes from './AppRoutes';

export interface Settings {
  balance: number;
}
export interface User {}

export interface Context {
  settings: Settings | null;
  currentUser: User | null;
}

export default function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    if (currentUser) {
      fetchSettings(currentUser).then((data) => {
        // @ts-ignore
        setSettings(data);
      });
    }
  }, [currentUser]);

  onAuthStateChanged(auth, (user) => {
    if (user !== null) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      // @ts-ignore
      setCurrentUser(user);

      const {
        // @ts-ignore
        stsTokenManager: { accessToken, expirationTime, refreshToken },
      } = user;

      const expires = new Date(expirationTime);

      const cookies = new Cookies();
      cookies.set('access_token', accessToken, { path: '/', expires });
      cookies.set('refresh_token', refreshToken, { path: '/', expires });
    } else {
      // User is signed out
      // ...
    }
  });

  const context: Context = { currentUser, settings };

  return (
    <div className="app">
      <Provider store={store}>
        <Helmet>
          <title>AskGPT</title>
          <meta
            name="description"
            content="A mysterious and powerful AI interface, whose mystery is only exceeded by its power."
          />
          <meta name="keywords" content="askgpt" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta charSet="utf-8" />
          <link rel="canonical" href="https://askgpt.cc" />
        </Helmet>
        <Router>
          <AppRoutes context={context} />
          {/*<Route path="/" element={<Login />} />*/}
          {/*<Route*/}
          {/*  path="/home"*/}
          {/*  element={<Page component={Chat} context={context} />}*/}
          {/*/>*/}
          {/*<Route path="/reset" element={<Reset />} />*/}
          {/*<Route path="/register" element={<Register />} />*/}
          {/*<Route path="/transactions" element={<Transactions />} />*/}
          {/*<Route*/}
          {/*  path="/payments"*/}
          {/*  element={<Page component={Payments} context={context} />}*/}
          {/*/>*/}
        </Router>
      </Provider>
    </div>
  );
}
