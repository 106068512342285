import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Container } from 'semantic-ui-react';

import { callApi } from '../../lib/api';
import CheckoutForm from './CheckoutForm';

// @ts-ignore
export default function Payments({ context }) {
  // const [session, setSession] = React.useState(null);
  const [clientSecret, setClientSecret] = useState('');
  const [stripePromise, setStripePromise] = useState(null);
  const { currentUser } = context;

  useEffect(() => {
    // load secret
    if (!stripePromise) {
      const promise = loadStripe(
        process.env.NODE_ENV === 'development'
          ? 'pk_test_51MWPdxJOZAzLMjG0SOcXMKM5al3pu1q5pdVuP4jyvhaovhGdj3RwXv0b5OoPrbAeaEViVocf9JfA5gkGu6A3ZXZ500WZHKcNGT'
          : 'pk_live_51MWPdxJOZAzLMjG0j3AMw11mgBzJ4XOIzoHawL6ZTxbr0X8jWHUAcnRrpRnYAVGZVJTH6yQE2yEo0GDxwFJM44GC00ArgIfB26'
      );
      // @ts-ignore
      setStripePromise(promise);
    }
  }, [currentUser, stripePromise]);

  useEffect(() => {
    if (currentUser) {
      console.log('currentUser', currentUser);
      callApi(
        '/payments/stripe/pay',
        'POST',
        { items: [{ id: 'chat-tokens-1000' }] },
        {
          accessToken: currentUser?.stsTokenManager?.accessToken,
        }
      )
        .then((data) => {
          console.log(data);
          setClientSecret(data.clientSecret);
        })
        .catch((err) => console.log(err));
    } else {
      console.log('no user');
    }
  }, [currentUser]);

  // const appearance = {
  //   theme: 'stripe',
  // };
  const options = {
    clientSecret,
    // appearance,
  };

  // @ts-ignore
  return (
    <div className="payments">
      <Container>
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            {/*// @ts-ignore*/}
            <CheckoutForm email={currentUser.email} />
          </Elements>
        )}
      </Container>
    </div>
  );
}
