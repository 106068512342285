import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Comment, CommentsState } from '../../features/comments/commentsSlice';
import ReactMarkdown from 'react-markdown';

// @ts-ignore
import CodeBlocks from 'remark-code-blocks';
import { Comment as CommentDiv } from 'semantic-ui-react';

interface Props {}

// Plugins
// remarkjs: https://github.com/remarkjs/react-markdown
// rehype:   https://github.com/rehypejs/rehype/blob/main/doc/plugins.md#list-of-plugins

const CommentsList: React.FC<Props> = () => {
  // Use the useSelector hook to access the comments from the Redux store
  const comments = useSelector(
    (state: { comments: CommentsState }) => state.comments.comments
  );
  /*const dispatch = */ useDispatch();

  // Use the useEffect hook to subscribe to updates in the comments slice
  useEffect(() => {
    // Whenever the comments slice updates, the component will re-render with the latest comments
  }, [comments]);

  return (
    <div>
      <CommentDiv.Group>
        {comments.map((comment: Comment) => (
          <CommentDiv key={comment.id}>
            <CommentDiv.Avatar src={comment.profileImage} />
            <CommentDiv.Content>
              <CommentDiv.Author as="a">{comment.name}</CommentDiv.Author>
              <CommentDiv.Metadata>
                <div>Today at 5:42PM</div>
              </CommentDiv.Metadata>
              <CommentDiv.Text>
                <ReactMarkdown remarkPlugins={[CodeBlocks]}>
                  {comment.text}
                </ReactMarkdown>
              </CommentDiv.Text>
              <CommentDiv.Actions>
                <CommentDiv.Action>Reply</CommentDiv.Action>
              </CommentDiv.Actions>
            </CommentDiv.Content>
          </CommentDiv>
        ))}
      </CommentDiv.Group>
    </div>
  );
};

export default CommentsList;
