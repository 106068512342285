import React, { useEffect, useState } from 'react';
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { Button } from 'semantic-ui-react';
import { getBaseUrl } from '../../lib/api';

// @ts-ignore
export default function CheckoutForm({ email }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      // @ts-ignore
      switch (paymentIntent.status) {
        case 'succeeded':
          // @ts-ignore
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          // @ts-ignore
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          // @ts-ignore
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          // @ts-ignore
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: getBaseUrl() + '/payment-complete',
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      // @ts-ignore
      setMessage(error.message);
    } else {
      // @ts-ignore
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  // const paymentElementOptions = { layout: 'tabs' };

  // @ts-ignore
  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <LinkAuthenticationElement
        id="link-authentication-element"
        // @ts-ignore
        // onChange={(e) => setEmail(e.target.value)}
        options={{ defaultValues: { email } }}
      />
      <PaymentElement id="payment-element" />
      <Button primary disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : 'Pay Now'}
        </span>
      </Button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
