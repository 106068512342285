//NavbarLg.jsx
import { Menu, Segment } from 'semantic-ui-react';
import React from 'react';
// @ts-ignore
export default function NavbarLg({ renderLinks }) {
  return (
    <Segment inverted attached size="mini">
      <Menu inverted secondary>
        {renderLinks()}
      </Menu>
    </Segment>
  );
}
