import React, { useState, useEffect } from 'react';
// import {
//   startedSpeaking,
//   stoppedSpeaking,
// } from '../../features/speaking/speakingSlice';

interface Props {
  text: string;
}

const TextToSpeech: React.FC<Props> = ({ text }) => {
  const [, /*speaking*/ setSpeaking] = useState(false);

  useEffect(() => {
    const synth = window.speechSynthesis;
    const speak = (text: string) => {
      if (synth.speaking) {
        console.error('Speech synthesis in progress');
        return;
      }

      const utterance = new SpeechSynthesisUtterance(text);
      utterance.onstart = (event) => {
        setSpeaking(true);
        // @ts-ignore
        // startedSpeaking();
      };

      utterance.onend = (event) => {
        setSpeaking(false);
        // @ts-ignore
        // stoppedSpeaking();
      };

      synth.speak(utterance);
    };

    speak(text);
  }, [text]);

  return <></>;

  // return <div>{speaking ? 'Speaking...' : 'Stopped'}</div>;
};

export default TextToSpeech;
