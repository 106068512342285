import React, { useState } from 'react';
import TextToSpeech from '../../components/TextToSpeech';
import { CommentsList, DictaphoneButton } from '../../components';
import { Button, Form, TextArea } from 'semantic-ui-react';
import { CloudUploadOutline } from 'react-ionicons';
import { addComment } from '../../features/comments/commentsSlice';
import svgLogoOpenAI from '../../assets/icons/openai.svg';
import { callApi } from '../../lib/api';
import { useDispatch } from 'react-redux';

const DEFAULT_QUESTION = 'What is the meaning of life?';

// @ts-ignore
export default function Chat({ context }) {
  const [currentText, setCurrentText] = useState(DEFAULT_QUESTION);
  const [currentReply, setCurrentReply] = useState('');
  const dispatch = useDispatch();

  const { currentUser } = context;

  function handleSend() {
    dispatch(
      addComment({
        id: Date.now(),
        name: `You`,
        text: currentText,
        profileImage: currentUser.photoURL,
        createdAt: Date.now(),
      })
    );

    // setCurrentText('');

    createAiQuery(currentText)
      .then((r) => {
        const {
          data: { response },
        } = r;

        setCurrentReply(response);

        dispatch(
          addComment({
            id: Date.now(),
            name: `ChatGPT`,
            text: response,
            profileImage: svgLogoOpenAI,
            createdAt: Date.now(),
          })
        );
      })
      .catch((e) => console.error(e));
  }

  // @ts-ignore
  function handleChange(event) {
    console.log(event);
    setCurrentText(event.target.value);
  }

  // @ts-ignore
  function handleTextUpdateCallback(text) {
    setCurrentText(text);
  }

  async function createAiQuery(queryText: string) {
    return callApi(
      '/conversations',
      'POST',
      {
        text: queryText,
      },
      {
        // @ts-ignore
        accessToken: currentUser?.stsTokenManager?.accessToken,
      }
    );
  }

  return (
    <div className="chat">
      <TextToSpeech text={currentReply}></TextToSpeech>
      <div>{<CommentsList />}</div>
      <div>
        <Form>
          <TextArea
            placeholder="What is the meaning of life?"
            value={currentText}
            onChange={handleChange}
          />
        </Form>
        <DictaphoneButton handleTextUpdateCallback={handleTextUpdateCallback} />
        <Button onClick={handleSend}>
          <CloudUploadOutline />
        </Button>
      </div>
    </div>
  );
}
