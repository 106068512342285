import { createSlice /*PayloadAction*/ } from '@reduxjs/toolkit';

export interface SpeakingState {
  speaking: boolean;
}

const initialState: SpeakingState = {
  speaking: false,
};

const speakingSlice = createSlice({
  name: 'speaking',
  initialState,
  reducers: {
    startedSpeaking: (state, action) => {
      console.log('started speaking...');
      state.speaking = true;
    },
    stoppedSpeaking: (state, action) => {
      console.log('stopped speaking...');
      state.speaking = false;
    },
  },
});

export const { startedSpeaking, stoppedSpeaking } = speakingSlice.actions;

export default speakingSlice.reducer;
