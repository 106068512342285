//Navbar.jsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Menu } from 'semantic-ui-react';

import { logout } from '../../firebase';

import NavbarMb from './NavbarMb';
import NavbarLg from './NavbarLg';
import {
  AddOutline,
  ListOutline,
  LogOutOutline,
  SchoolOutline,
  TicketOutline,
} from 'react-ionicons';
import { BalanceDisplay, VersionInfo } from '..';

const INITIAL_STATE = 'home';

interface NavbarProps {
  context: any;
}

export default function Navbar({ context }: NavbarProps) {
  const [activeItem, setActiveItem] = useState(INITIAL_STATE);

  async function handleLogout() {
    await logout();
    setActiveItem(INITIAL_STATE);
    // redirect back to /
    window.location.href = '/';
  }

  const handleItemClick = (e: any, { name }: any) => setActiveItem(name);
  const renderLinks = () => {
    return (
      <>
        <SchoolOutline color={'#ffffff'} height="32px" width="32px" />

        <Menu.Item
          as={Link}
          to="/home"
          name="newConversation"
          active={activeItem === 'newConversation'}
          onClick={handleItemClick}
        >
          <AddOutline color="#ffffff" /> New Chat
        </Menu.Item>

        <Menu.Item
          as={Link}
          to="/payments"
          name="payments"
          active={activeItem === 'payments'}
          onClick={handleItemClick}
        >
          <TicketOutline color="#ffffff" /> Buy Tokens
        </Menu.Item>

        <Menu.Item
          as={Link}
          to="/transactions"
          name="transactions"
          active={activeItem === 'transactions'}
          onClick={handleItemClick}
        >
          <ListOutline color="#ffffff" /> Transactions
        </Menu.Item>

        <Menu.Item
          as={Link}
          to="/home"
          name="logout"
          active={activeItem === 'logout'}
          onClick={handleLogout}
        >
          <LogOutOutline color="#ffffff" /> Logout
        </Menu.Item>

        <Menu.Item name="balance">
          <BalanceDisplay context={context} />
        </Menu.Item>

        <Menu.Item name="version">
          <VersionInfo />
        </Menu.Item>
      </>
    );
  };

  const none = useMediaQuery({ query: '(max-width:576px)' });
  const sm = useMediaQuery({ query: '(min-width:576px)' });
  const md = useMediaQuery({ query: '(min-width:768px)' });
  const lg = useMediaQuery({ query: '(min-width:992px)' });
  const xl = useMediaQuery({ query: '(min-width:1200px)' });
  const xxl = useMediaQuery({ query: '(min-width:1400px)' });
  const size = { none, sm, md, lg, xl, xxl };
  return (
    <>
      {size.sm ? (
        <NavbarLg renderLinks={renderLinks} />
      ) : (
        <NavbarMb renderLinks={renderLinks} />
      )}
    </>
  );
}
