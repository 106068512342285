import React, { useState, useEffect } from 'react';

import { Column, useTable } from 'react-table';
import { fetchTransactions } from '../../features/transactions/transactionsSlice';

const columns = [
  {
    Header: 'Model',
    accessor: 'model',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Date',
    accessor: 'createdAt',
  },
] as Column[];

// @ts-ignore
export default function Transactions({ context }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchTransactions(context.currentUser)
      .then((d) => {
        // @ts-ignore
        d && d?.transactions && setData(d?.transactions);
      })
      .catch((err) => console.log(err));
  }, [context.currentUser]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
