import React, { useEffect, useState } from 'react';

import { Context } from '../App';

interface Props {
  context: Context;
}

export default function BalanceDisplay(props: Props) {
  const {
    context: { settings },
  } = props;
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    settings && setBalance(settings.balance);
  }, [settings]);

  return (
    <div className="balance-display">
      <div className="balance-display__balance">{balance} tokens</div>
    </div>
  );
}
