import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import './Page.css';
import { Navbar } from '../index';

export default function Page(props: any) {
  const { component: Component, context } = props;
  return (
    <div className="page">
      <Navbar context={context} />
      <Component context={context} />
    </div>
  );
}
