import React, { useState, useEffect } from 'react';

const UserBrowserInfo = () => {
  const [browserName, setBrowserName] = useState('');
  const [browserVersion, setBrowserVersion] = useState('');

  useEffect(() => {
    const userAgent = navigator.userAgent;
    let browserName = '';
    let browserVersion = '';

    if (userAgent.indexOf('Chrome') !== -1) {
      browserName = 'Chrome';
      browserVersion = userAgent
        .substring(userAgent.indexOf('Chrome') + 7)
        .split(' ')[0];
    } else if (userAgent.indexOf('Firefox') !== -1) {
      browserName = 'Firefox';
      browserVersion = userAgent
        .substring(userAgent.indexOf('Firefox') + 8)
        .split(' ')[0];
    } else if (userAgent.indexOf('Safari') !== -1) {
      browserName = 'Safari';
      browserVersion = userAgent
        .substring(userAgent.indexOf('Safari') + 7)
        .split(' ')[0];
    } else if (userAgent.indexOf('Edge') !== -1) {
      browserName = 'Edge';
      browserVersion = userAgent
        .substring(userAgent.indexOf('Edge') + 5)
        .split(' ')[0];
    } else if (userAgent.indexOf('MSIE') !== -1) {
      browserName = 'Internet Explorer';
      browserVersion = userAgent
        .substring(userAgent.indexOf('MSIE') + 5)
        .split(' ')[0];
    }

    setBrowserName(browserName);
    setBrowserVersion(browserVersion);
  }, []);

  return (
    <div>
      <p>
        You are using {browserName} {browserVersion}
      </p>
    </div>
  );
};

export default UserBrowserInfo;
