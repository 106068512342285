import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link, useNavigate } from 'react-router-dom';

import {
  auth,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from '../../firebase';

import './Register.css';
import { Button, Form, Input } from 'semantic-ui-react';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [user, loading, error] = useAuthState(auth);
  if (error) {
    console.error(error);
  }
  const navigate = useNavigate();
  const register = () => {
    if (!name) alert('Please enter name');
    return registerWithEmailAndPassword(name, email, password);
  };
  useEffect(() => {
    if (loading) return;
    if (user) navigate('/');
  }, [user, loading, navigate]);
  return (
    <Form className="register">
      <div className="register-container">
        <Input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Full Name"
        />
        <Input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <Input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <Button primary onClick={register}>
          Register
        </Button>
        <Button secondary onClick={signInWithGoogle}>
          Register with Google
        </Button>
        <div>
          Already have an account? <Link to="/">Login</Link> now.
        </div>
      </div>
    </Form>
  );
}
export default Register;
