import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './components';

import 'semantic-ui-css/semantic.min.css';
import './index.css';
// import { DevSupport } from '@react-buddy/ide-toolbox';
// import { ComponentPreviews, useInitial } from './dev';

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
  // <DevSupport ComponentPreviews={ComponentPreviews} useInitialHook={useInitial}>
  <App />
  // </DevSupport>
  // </React.StrictMode>
);
